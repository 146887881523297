import './App.css';
import Routes from './app/shared/routes';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from "@vercel/speed-insights/react"

function App() {
  return (
    <div className="app">
      <Routes />
      <Analytics />
      <SpeedInsights />
    </div>
  );
}

export default App;

import { useState, useEffect } from 'react';
import { Fade } from "react-awesome-reveal";
import { FaInstagram, FaEnvelope, FaWhatsapp } from "react-icons/fa6";
import player_1 from'../../../assets/images/player_1.png'
import player_2 from'../../../assets/images/player_2.png'

import './style.css'

function Footer() {
    const [year, setYear] = useState(new Date().getFullYear());

    useEffect(() => {
        setYear(new Date().getFullYear());
    }, []);
    return (
        <>
            <section id="footer" className='section w-100'>
                <img className="player_1 hide-mobile" src={ player_1 } alt="Player Vector" />
                <img className="player_2 hide-mobile" src={ player_2 } alt="Player Vector" />
                <Fade triggerOnce={true}>
                    <div className='section-title'>
                        <span>Entre em <span className='text-secondary'>Contato!</span></span>
                    </div>
                    <div className="subtitle">
                        Tem alguma dúvida? Fique a vontade para entrar em contato!
                    </div>
                </Fade>

                <div className="contact" id="contact">
                    <Fade>
                        <div>
                            <span className="contact-icon text-secondary">
                                <FaEnvelope />
                            </span>
                            <a href="mailto:suporte@futja.app">suporte@futja.app</a>
                        </div>
                        <div>
                            <span className="contact-icon text-secondary">
                                <FaInstagram />
                            </span>
                            <a href="https://www.instagram.com/futja.app" target="_blank" rel="noreferrer">@futja.app</a>
                        </div>
                        <div>
                            <span className="contact-icon text-secondary">
                                <FaWhatsapp />
                            </span>
                            <a href="https://api.whatsapp.com/send?phone=5532991241448&text=Olá! Gostaria de tirar algumas duvidas sobre o app FutJá" target="_blank" rel="noreferrer">+55 32 99124-1448</a>
                        </div>
                    </Fade>
                </div>
            </section>
            <footer>
                <span className="text-secondary">©{year} FutJá</span> Todos os direitos reservados
            </footer>
        </>
    )
}

export default Footer

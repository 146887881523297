import React from 'react';
import './style.css';

function deleteAccount() {
    return(
        <div className="deleteAccount">
            <div className="container">
                <section className="text-center border-bottom pb10 border-box">
                    <p className="font-md bold">
                        Bem-vindo à página de exclusão de conta do <strong className="text-secondary">FutJá</strong>.
                    </p>
                    <p>
                        Caso deseje solicitar a exclusão de sua conta e dos dados pessoais que coletamos, siga as etapas descritas abaixo:
                    </p>
                </section>
                
                <section>
                    <h2 className="subtitle">Como Solicitar a Exclusão</h2>
                    <p className="paragraph">Para solicitar a exclusão de sua conta, você pode:</p>
                    <ul>
                        <li>
                            Dentro do app navegue em:
                            <ul className="list">
                                <li>Perfil</li>
                                <li>Configurações</li>
                                <li>Gerenciar informações da conta</li>
                                <li>Excluir minha conta</li>
                            </ul>
                        </li>
                        
                        <li>
                            Enviar um e-mail para <strong className="text-secondary">suporte@futja.app</strong> com o assunto "Exclusão de Conta" com:
                            <ul className="list">
                                <li>Nome completo</li>
                                <li>E-mail utilizado no cadastro</li>
                                <li>Usuário</li>
                            </ul>
                        </li>
                    </ul>
                </section>
                
                <section>
                    <h2 className="subtitle">Dados que Serão Mantidos e Excluídos</h2>
                    <p className="paragraph">
                        Ao solicitar a exclusão, todos os seus dados pessoais, como nome, e-mail, telefone e qualquer outra
                        informação que possa identificá-lo como pessoa, <br/>
                        serão removidos de nossos servidores em um prazo de até <strong className="text-secondary">30 dias</strong>. <br/><br/>
                        No entanto, dados relacionados a notas e avaliações que você tenha feito serão mantidos de forma
                        anônima, <br/>
                        para que não prejudiquem outros usuários e a integridade das estatísticas da plataforma.
                    </p>
                </section>
                
                <section className="text-center">
                    <h2 className="subtitle">Informações Adicionais</h2>
                    <p className="paragraph">
                        Para mais detalhes sobre como lidamos com seus dados, consulte nossa <br/>
                        <a className="text-secondary" href="https://api.futja.app/privacy" target="_blank" rel="noopener noreferrer">Política de Privacidade</a> e <a className="text-secondary" href="https://api.futja.app/terms" target="_blank" rel="noopener noreferrer">Termos de Uso</a>.
                    </p>
                </section>
                
                <section className="text-center">
                    <div className="btn-container">
                        <a href="mailto:suporte@futja.app?subject=Exclusão%20de%20Conta" className="button">
                            Solicitar Exclusão
                        </a>
                    </div>
                </section>
            </div>

        </div>
    )
}

export default deleteAccount
